import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../components/Input/Input";
import { setOnboardingDetails } from "../../../customerAction";
import { Select } from "antd";
import { getPaymentMethodOption } from "../../../../../util/Utility";
import ErrorMessage from "../../../../../components/ErrorMessage";
import isEmail from "validator/lib/isEmail";
import { useTranslation } from "react-i18next";
import {
  isValidPhoneNumber,
  getCountries,
  parsePhoneNumber,
  PhoneNumber,
} from "libphonenumber-js";

const options = getPaymentMethodOption();

const countryAbbr={
  '+31':'NL',
  "+49":"DE",
    "+43":"AT",
    "+33":"FR",
    "+32":"BE"
}

const countryCodeMap={
  'Netherlands':'+31',
  'Germany':'+49',
  'France':'+33',
  'Austria':'+43',
  'Belgium':"+32"
}

function MosqueStepTwo() {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.mosqueReducer.countries);
  const {t} = useTranslation()
  const [errors, setErrors] = useState({});

  const onboardingDetails = useSelector(
    (state) => state.customerReducer.onboardingDetails
  );
  const [user, setUserData] = useState({
    contact_name: "",
    contact_email: "",
    code: "",
    contact_phone: "",
    contact_address: "",
    country_code: onboardingDetails?.country_code || "+31",
  });

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (
      !onboardingDetails.contact_email ||
      !isEmail(onboardingDetails.contact_email)
    ) {
      isValid = false;
      error["contact_email"] = "Please enter valid email address";
    }
    return {
      isValid,
      error,
    };
  };

  useEffect(() => {
    try {
      const countryCode = parsePhoneNumber(
        `${onboardingDetails?.country_code}${onboardingDetails.contact_phone}`
      )?.country;
      if (countryCode) {
        handleChangeUser("country_abbreviation", countryCode);
      }
    } catch (e) {
      console.log(e);
    }
  }, [onboardingDetails?.country_code, onboardingDetails.contact_phone]);

  const handleChangeUser = (keyName, keyValue) => {
    if (keyName === "contact_email") {
      keyValue = keyValue.toLowerCase();
    }
    // if(keyName === "contact_phone"){
    //   handleMobileValidation(keyValue)
    // }
    dispatch(setOnboardingDetails(keyName, keyValue));
    let update = { ...user };
    update[keyName] = keyValue;
    setUserData(update);
  };

  const handleEmailValidation = () => {
    setErrors({});
    let validate = handleValidation();
    if (!validate.isValid) {
      setErrors(validate.error);
    }
  };

  const handleMobileValidation = (phone) => {
    const cleanedPhone = phone.replace(/\D/g, "");
    if (
      cleanedPhone.length < 4 || cleanedPhone.length > 13
    ) {
      setErrors((prev) => ({
        ...prev,
        contact_phone: t("Please enter a valid phone number"),
      }));
    } else {
      setErrors((prev) => ({ ...prev, contact_phone: "" }));
    }
  };

  useEffect(()=>{
    dispatch(setOnboardingDetails('country_code', countryCodeMap[onboardingDetails.entity_country]))
  },[onboardingDetails.entity_country])

  return (
    <div>
      <div className="mosque-form">
        <h4>{t('Voer beheerdersgegevens in')}</h4>
        <div className="row">
          <div className="col-lg-6 mb-3">
            <label>
              {t('Voor-en achternaam')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails.contact_name}
              onChange={(e) => handleChangeUser("contact_name", e.target.value)}
              type="text"
              placeholder="Contactpersoon"
              className="input-box"
            />
          </div>
          <div className="col-lg-6 mb-3">
            <label>
              {t('E-mailadres')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              onBlur={() => handleEmailValidation()}
              value={onboardingDetails.contact_email}
              onChange={(e) =>
                handleChangeUser("contact_email", e.target.value)
              }
              type="text"
              placeholder={t("E-mailadres")}
              className="input-box"
            />
            {errors.contact_email && (
              <ErrorMessage>{t(errors.contact_email)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 mb-3">
            <label>
              {t('Contactnummer')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              // onBlur={() => handleMobileValidation()}
              onFocus={() =>
                setErrors((prev) => ({ ...prev, contact_phone: "" }))
              }
              value={onboardingDetails.contact_phone}
              countryCodes={true}
              countryCodeValue={onboardingDetails?.country_code}
              setCountryCode={(e) => {
                handleChangeUser("country_code", e);
              }}
              onChange={(e) =>
                handleChangeUser("contact_phone", e.target.value)
              }
              placeholder="785-785-464"
              className="input-box"
              type="number"
              pattern="[0-9]"
              height="52px"
              overflow="unset"
            />
            {errors.contact_phone && (
              <ErrorMessage>{t(errors.contact_phone)}</ErrorMessage>
            )}
          </div>
          {/*<div className="col-lg-6 mb-3">
            <label>
              Adres<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails?.contact_address}
              onChange={(e) => handleChangeUser("contact_address", e.target.value)}
              type="text"
              placeholder="Contactadres"
              className="input-box"
            />
            {errors.contact_address && <ErrorMessage>{errors.contact_address}</ErrorMessage>}
            </div>*/}
        </div>
        <br />
      </div>
    </div>
  );
}

export default MosqueStepTwo;
