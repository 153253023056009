import React, { useState } from "react";
//import SelectBoxCountry from "./SelectBoxCountry";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingDetails } from "../../../customerAction";
import Dropdown from "../../../../../components/Dropdown";
import { Spinner } from "react-bootstrap";
import { fetchCities } from "../../../../Mosque/mosqueAction";
import { useTranslation } from "react-i18next";
import { fontSize } from "styled-system";
import CountrySelect from "../../../../../components/CustomDropdown/CountrySelect";
import { STORE_CITIES } from "../../../../Mosque/mosqueActionType";

const getUniqueLabels = (list) => {
  const map = new Map();

  list.forEach((item) => {
    if (!map.has(item.label)) {
      map.set(item.label, item);
    }
  });

  return Array.from(map.values());
};

const customCountries = [
  { label: "Netherlands", value: "Netherlands" },
  { label: "Germany", value: "Germany" },
  { label: "France", value: "France" },
  { label: "Austria", value: "Austria" },
  { label: "Belgium", value: "Belgium" },
]

const countryCodeMap={
  'Netherlands':'+31',
  'Germany':'+49',
  'France':'+33',
  'Austria':'+43',
  'Belgium':"+32"
}

function MosqueStepOne() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onboardingDetails = useSelector(
    (state) => state.customerReducer.onboardingDetails
  );

  const isFetchingCities = useSelector(
    (state) => state.mosqueReducer.isFetchingCities
  );
  const countries = useSelector((state) => state.mosqueReducer.countries);
  const cities = useSelector((state) => state.mosqueReducer.cities);
  const [selectedCity, setSelectedCity] = useState("");

  const [errors, setError] = useState({});
  const [user, setUserData] = useState({
    entity_name: "",
    entity_address: "",
    entity_pincode: "",
    entity_country: "",
    entity_city: "",
    country_code:'',
    paymentGatewayMode:''
  });

  const handleChangeUser = (keyName, keyValue) => {
    let update = { ...user, [keyName]: keyValue };

    if (keyName === "entity_country") {
        const countryCode = countryCodeMap[keyValue];
        update['country_code'] = countryCode;
        // Dispatch both updates together
        dispatch(setOnboardingDetails('entity_country', keyValue));
        dispatch(setOnboardingDetails('country_code', countryCode));
    } else {
        dispatch(setOnboardingDetails(keyName, keyValue));
    }

    setError({});
    setUserData(update);
    console.log( onboardingDetails); // Debugging
};

  const handleCountryChange = (value) => {
    setSelectedCity(""); // Reset selected city when country changes
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  /*const handleValidation = () => {
    let isValid = true;
    let newErrors = {};
    if (!user.entity_name) {
      isValid = false;
      newErrors["entity_name"] = "Please enter entity_name";
    }
    if (!user.entity_address) {
      isValid = false;
      newErrors["entity_address"] = "please enter entity_address";
    }
    if (!user.entity_pincode) {
      isValid = false;
      newErrors["entity_pincode"] = "please enter entity_pincode";
    }
    if (!user.entity_country) {
      isValid = false;
      newErrors["entity_country"] = "please enter country";
    }
    if (!user.entity_city) {
      isValid = false;
      newErrors["entity_city"] = "please enter city";
    }
    return {
      isValid,
      errors: newErrors,
    };
  };

  const handleNext = () => {
    const check = handleValidation();
    if (check.isValid) {
      // let Updatesteps = {
      //     stepInfo: newstepInfo
      // }
      // setSegment("MosqueStepTwo");
      // window.location
    } else {
      setError(check.errors);
    }
  };*/
  console.log('oboarding details : ', onboardingDetails);
  console.log('react app stage: ', process.env.REACT_APP_STAGE);
  
  return (
    <div>
      <div className="mosque-form">
        <h4>{t("Voer Moskeegegevens in")}</h4>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <label>
              {t("Naam Moskee")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails?.entity_name}
              onChange={(e) =>
                handleChangeUser(
                  "entity_name",
                  e.target.value?.replace(/[^a-zA-Z\s]/g, "")
                )
              }
              type="text"
              placeholder={t("Voer Naam Moskee In")}
              className="input-box"
            />
            {errors && errors.entity_name ? (
              <span className="error-msg">{errors.entity_name}</span>
            ) : null}
          </div>
          <div className="col-lg-6 mb-3">
            {!countries?.length ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <label>
                  {t("Land")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <Dropdown
                  allowSearch
                  variant="outlinedGreen"
                  defaultText={t("Selecteer Land")}
                  color="#1A1A1A"
                  fontWeight="400"
                  style={{ fontSize: "18px" }}
                  width="100%"
                  height="54px"
                  Icon="none"
                  selectedValue={onboardingDetails?.entity_country}
                  onOptionClick={(item) => {
                    handleChangeUser("entity_country", item?.value);
                    handleChangeUser("country_code", item?.phoneCode);
                    handleChangeUser("country_abbreviation", item?.countryCode);
                    handleChangeUser("entity_city", "");
                    dispatch({
                                                type: STORE_CITIES,
                                                cities: [],
                              });
                  }}
                  options={customCountries}
                />
              </>
            )}
            {/*<SelectBoxCountry
             selectedCountry={onboardingDetails?.entity_country}
             handleChangeCountry={(value) => handleChangeUser("entity_country", value)}
             handleChangeCity={(value) => handleChangeUser("entity_city", value)} 
            />*/}
          </div>
          <div className="col-lg-6 mb-3">
            {isFetchingCities ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <label>
                  {t("Stad")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                {/* <Dropdown
                  className="custom-dropdown"
                  allowSearch
                  variant="outlinedGreen"
                  defaultText={t("Selecteer stad")}
                  color="#1A1A1A"
                  fontWeight="400"
                  width="100%"
                  style={{fontSize:'18px'}}
                  height="54px"
                  Icon="none"
                  selectedValue={onboardingDetails?.entity_city}
                  onOptionClick={(item) =>
                    handleChangeUser("entity_city", item?.value)
                  }
                  options={cities}
                /> */}
                <CountrySelect
                  defaultText={t("Selecteer stad")}
                  handleChangeUser={handleChangeUser}
                  onboardingDetails={onboardingDetails}
                  cities={cities}
                />
              </>
            )}
          </div>
          <div className="col-lg-6 mb-4">
            <label>
              {t("Adres")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails?.entity_address}
              onChange={(e) =>
                handleChangeUser("entity_address", e.target.value)
              }
              type="text"
              placeholder={t("Voer Adres Moskee In")}
              className="input-box"
            />
            {errors && errors.entity_address ? (
              <span className="error-msg">{errors.entity_address}</span>
            ) : null}
          </div>

          <div className="col-lg-6 mb-3">
            <label>
              {t("Postcode")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails?.entity_pincode}
              onChange={(e) =>
                handleChangeUser("entity_pincode", e.target.value)
              }
              placeholder={t("Voer postcode in")}
              className="input-box"
            />
            {errors && errors.entity_pincode ? (
              <span className="error-msg">{errors.entity_pincode}</span>
            ) : null}
          </div>
          {
             process.env.REACT_APP_STAGE === 'development' &&
            <div className="col-lg-6 mb-3">
            <label>
              {t("PaymentGateway Mode")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <div className="radio-group" style={{display:'flex', alignItems:'center',gap:'20px'}}>
              <label style={{display:'flex', alignItems:'center',gap:'10px'}}>
                <input
                  type="radio"
                  name="paymentGatewayMode"
                  value="TEST"
                  checked={onboardingDetails?.paymentGatewayMode === "TEST"}
                  onChange={() =>
                    handleChangeUser("paymentGatewayMode", "TEST")
                  }
                />
                {t("Test")}
              </label>
              <label style={{display:'flex', alignItems:'center',gap:'10px'}}>
                <input
                  type="radio"
                  name="paymentGatewayMode"
                  value="LIVE"
                  checked={onboardingDetails?.paymentGatewayMode === "LIVE"}
                  onChange={() =>
                    handleChangeUser("paymentGatewayMode", "LIVE")
                  }
                />
                {t("Production")}
              </label>
            </div>
            {errors && errors.paymentGatewayMode ? (
              <span className="error-msg">{errors.paymentGatewayMode}</span>
            ) : null}
          </div>}
        </div>

        <br />
      </div>
    </div>
  );
}

export default MosqueStepOne;
