import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../css/addmember.css";
import Button from "../../../../../components/Buttons/Button";
import { Select } from "antd";
import { getPaymentRecurringOption } from "../../../../../util/Utility";
import Icon from "../../../../../components/Icon/Icon";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import { setPlanDetails, addNewPlan, updateAddedNewFlag } from "../../../mosqueAction";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Input from "../../../../../components/Input";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import CurrencyInput from "react-currency-input-field";




function AddNewPlan() {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const options = [
    {
      label: t("Maandelijks"),
      value: "monthly",
    },
    /*{
      label: "Quarterly",
      value: "quarterly",
    },
    {
      label: "Yearly",
      value: "yearly",
    },*/
  ];
  const [isNext, setIsNext] = useState(false);
  const [errors, setErrors] = useState({});

  const planDetails = useSelector((state) => state.mosqueReducer.memberPlans?.plan_details);
  const isAddedNew = useSelector((state) => state.mosqueReducer.memberPlans?.isAddedNew);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);

  const formValidation = () => {
    return !!planDetails?.plan_name.trim() &&
      !!planDetails?.amount &&
      !!planDetails?.recurring_type 
  }

  useEffect(() => {
    if (planDetails) {
      setIsNext(formValidation())
    }
  }, [planDetails])

  const optionPaymentDate = () => {
    let option = [];
    for (let i = 1; i <= 20; i++) {
      const data = {};
      data.label = i;
      data.value = i;
      option.push(data);
    }
    return option;
  }

  const handleChangeUser = (keyName, keyValue) => {
    setErrors({});
    console.log(keyName,keyValue);
    dispatch(setPlanDetails(keyName, keyValue))
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!planDetails.plan_name) {
      isValid = false;
      error["plan_name"] = "Please enter plan name";
    }
    if (!planDetails.amount) {
      isValid = false;
      error["amount"] = "Please enter amount";
    }
    if (!planDetails.recurring_type) {
      isValid = false;
      error["recurring_type"] = "Please select recurring type";
    }
    return {
      isValid,
      error
    };
  }

  const handleAddPlan = () => {
    setErrors({});
    let validate = handleValidation();
    if (validate.isValid) {
      const details = {...planDetails};
      details.organisation_id = activeEntity?.organisation_id;
      details.entity_id = activeEntity?._id;
      details.payment_day = dayjs().date();

      const trimmedDetails = {};
      // Trim each attribute and assign it to trimmedDetails object
      for (const key in details) {
        if (typeof details[key] === "string") {
          trimmedDetails[key] = details[key].trim();
        } else {
          trimmedDetails[key] = details[key];
        }
      }

      if (activeEntity) {
        dispatch(addNewPlan(trimmedDetails));
      }
    } else {
      setErrors(validate.error);
    }
  }

  const handleAddNew = () => {
    dispatch(updateAddedNewFlag(false));
    window.location.href = `/mosque/${activeEntity?._id}/subscriptions/new`
  };
useEffect(()=>{
  dispatch(setPlanDetails("recurring_type","monthly"))
},[dispatch])
  return (
    <div className="add_form subscription-form form_width" style={{height:"400px"}}>
      <div className="mosque-form">
        <div className="row">
          <div className="col-lg-6">
            <label>
            {t('Naam lidmaatschap')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={planDetails?.plan_name}
              onChange={(e) => handleChangeUser("plan_name", e.target.value)}
              type="text"
              placeholder={t("Naam lidmaatschap")}
              className="input-box"
            />
            {errors.plan_name && <ErrorMessage>{errors.plan_name}</ErrorMessage>}
          </div>
          <div className="col-lg-6 ">
            <label>
            {t('Bedrag lidmaatschap')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            {/* <Input
              value={planDetails?.amount}
              // currencySymbols={true}
              // setCurrencySymbols={(e) => handleChangeUser("currency_symbol", e)}
              onChange={(e) => handleChangeUser("amount", e.target.value)}
              type="number"
              placeholder={t("Bedrag lidmaatschap")}
              className="input-box"
            /> */}
            <CurrencyInput 
            value={planDetails?.amount}
            onValueChange={(value) => { 
              const numericValue = parseFloat(value);
              if (numericValue === 0 || value === "0.00" || value === "0.0") {
                return;
              }
            handleChangeUser("amount", value)}}
            decimalSeparator="."
            decimalsLimit={2}
            step={10}
            groupSeparator=","
            allowNegativeValue={false}
            placeholder={t("Bedrag lidmaatschap")}
            className="input-box"
            />
            {errors.amount && <ErrorMessage>{t(errors.amount)}</ErrorMessage>}
          </div>

          <div className="col-lg-6 pt-4">
            <label>
            {t('Factureringsfrequentie')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Select
              size={"middle"}
              value={planDetails?.recurring_type || t("Maandelijks")}
              defaultValue={t("Maandelijks")}
              // onChange={(value) => handleChangeUser("recurring_type", value)}
              style={{ width: "100%" }}
              disabled
              options={options}
            />
            {errors.recurring_type && <ErrorMessage>{t(errors.recurring_type)}</ErrorMessage>}
          </div>
          {/*<div className="col-lg-6 pt-4 mb-4">
            <label>
            Betalingsdatum<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Select
              size={"middle"}
              value={planDetails?.payment_day || "Selecteer datum"}
              defaultValue="Selecteer datum"
              onChange={(value) => handleChangeUser("payment_day", value)}
              style={{ width: "100%" }}
              options={optionPaymentDate()}
            />
            {errors.payment_day && <ErrorMessage>{errors.payment_day}</ErrorMessage>}
          </div>*/}
        </div>
        <Button
          type={!isNext ? "disabled" : "primary"}
          text={t("Gegevens opslaan")}
          width="15%"
          mt="4"
          mx="auto"
          id="btn-margin-top"
          height="60px"
          className='btn-margin-top'
          onClick={() => handleAddPlan()}
        />
        <br />
        <DefaultModal
          show={isAddedNew}
          onHide={() => dispatch(updateAddedNewFlag(false))}
          className="add-mosque-modal"
          centered={true}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <div>
            <Icon
              iconName="modalClose"
              iconSize="xs"
              style={{ marginLeft: "auto" }}
              onClick={() => dispatch(updateAddedNewFlag(false))}
            />
            <div className="text-center add-mosque-modal-content">
              <Icon
                iconName="congratulations"
                style={{ justifyContent: "center" }}
              />
              <div>{t('Gefeliciteerd')}</div>
              <div>
              {t('We hebben het nieuwe lidmaatschap toegevoegd.')}
              </div>
              {/*<button onClick={() => handleAddNew()}>Nieuw lidmaatschap toevoegen </button>*/}
            </div>
          </div>
        </DefaultModal>
      </div>
    </div>
  );
}

export default AddNewPlan;
