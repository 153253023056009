import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import axios from "axios";
import uploadImg from "./cloud_upload.svg";
import Label from "../../../../../components/Label";
import { truncate } from "lodash";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";

function UploadXls({refetchCustomerList}) {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploaledFile]= useState(null)
  const [uploadedFileLink, setUploaledFileLink] = useState(null)
  const [message, setMessage] = useState(null)

  const uploadFile = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    
    if (!file) return;

    setIsUploading(true);
    setProgress(0);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("entityId", activeEntity._id)

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 85) {
          return prevProgress + 1;
        } else {
          clearInterval(timer);
          return prevProgress;
        }
      });
    }, 200);

    try {
     const res =  await axios.post(`${process.env.REACT_APP_API_URL}file/upload/xls`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted < 85) {
            setProgress(percentCompleted);
          }
        },
      });
     
      setUploaledFileLink(res.data?.data?.link)
      setUploaledFile(truncate(file.name,{length:20}))
      setProgress(100);
      
    } catch (error) {
      console.error("File upload error:", error);
      alert("File upload failed");
    } finally {
      clearInterval(timer);
      setTimeout(() => {setIsUploading(false);  alert("Bestand succesvol geüpload. Je ontvangt een e-mail nadat de gelovigen zijn geregistreerd.");}, 1000);
      // setTimeout(()=>  window.location.href = `/mosque/${activeEntity?._id}/info`,)
    }
  };

  return (
    <>
      <input
        id="xls-upload"
        type="file"
        onChange={uploadFile} // Trigger only on file selection
        style={{ display: "none" }}
        className="fill-bt"
        accept=".xlsx, .xls"
      />
      <label
        htmlFor="xls-upload"
        className={isUploading ? "gray-bt" : "fill-bt"}
        style={{ height: "auto", width: "max-content", padding: "10px 20px",pointerEvents:isUploading?'none':'' }}
      >
        {t("Upload xls")}
      </label>
      <small style={{marginLeft:'-40px', color:'blue', cursor:'pointer'}} onClick={()=> window.open(uploadedFileLink)}>{uploadedFile}</small>
      {/* <div className="progress-dialog">
                    <img src={uploadImg} alt='cloud'/>
                    <progress style={{width:'100%'}} value={progress} max="100" />
                    <p style={{fontSize:'20px', fontWeight:'700', color:'#1a1a1a'}}>{t("Excel Uploading")} {progress}%</p>
                    <p style={{fontSize:'18px', fontWeight:'400', color:'#7e7e7e'}}>{t('This will only take a moment to process the file')}</p>
                </div> */}
      <DefaultModal
        show={isUploading}
        onHide={() => setIsUploading(false)}
        className="congrats-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="progress-dialog">
          <img src={uploadImg} alt="cloud" height='150px' width='150px'  style={{marginBottom:'30px'}}/>
          {/* <progress style={{width:'100%'}} value={progress} max="100" /> */}
          <div className="progress-container" >
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>

          <p style={{ fontSize: "20px", fontWeight: "700", color: "#1a1a1a" }}>
            {t("Excel Uploading")} {progress}%
          </p>
          <p style={{ fontSize: "18px", fontWeight: "400", color: "#7e7e7e" }}>
            {t("This will only take a moment to process the file")}
          </p>
        </div>
      </DefaultModal>
      {/* { message && <p>{message}</p>} */}
    </>
  );
}

export default UploadXls;
