import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons/Button";
import Label from "../../../../../components/Label";
import { Select, DatePicker } from "antd";
import { showNotification } from "../../../../../util/Utility";
import styled from "styled-components";
import { callApiV2 } from "../../../../../util/apiCaller";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../../components/ErrorMessage";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon/Icon";
import dayjs from "dayjs";
import { isValidPhoneNumber } from "libphonenumber-js";
import { UPDATE_APPROVAL_STATUS } from "../../../mosqueActionType";
import {
  fetchCities,
  memberApprove,
  memberDecline,
} from "../../../mosqueAction";
import Dropdown from "../../../../../components/Dropdown";
import { useTranslation } from "react-i18next";
import { STORE_CITIES } from "../../../mosqueActionType";
import { getPaymentMethodOption } from "../../../../../util/Utility";
import CitySelectUser from "../../../../../components/CustomDropdown/CitySelectUser";
import { fontWeight } from "styled-system";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const countryAbbr={
  '+31':'NL',
  "+49":"DE",
    "+43":"AT",
    "+33":"FR",
    "+32":"BE"
}

const paymentMethodOption = [
  {
    label: "Automatische incasso",
    value: "automatic",
  },
  {
    label: "Gratis abonnement",
    value: null,
  },
];

const customCountries = [
  { label: "Netherlands", value: "Netherlands" },
  { label: "Germany", value: "Germany" },
  { label: "France", value: "France" },
  { label: "Austria", value: "Austria" },
  { label: "Belgium", value: "Belgium" },
]

const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }
  .ant-picker-focused {
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1a1a1a;
  }
`;
const options = getPaymentMethodOption();

const AddForm = ({ details = null, memberSubId = null, approvalId = null }) => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.mosqueReducer.countries);
  const cities = useSelector((state) => state.mosqueReducer.cities);
  const [isNext, setIsNext] = useState(false);
  const [isAddedNew, setIsAddedNew] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isDecline, setIsDecline] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [activeMemberDetails, setActiveMemberDetails] = useState(null);
  const [isFreeSubscription, setIsFreeSubscription] = useState(false);
  const { t } = useTranslation();
  const [isManualPay, setIsManualPay] = useState(false);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([
    {
      label: t("Automatische incasso"),
      value: "automatic",
    },
    {
      label: t("Gratis abonnement"),
      value: null,
    },
  ]);
  const isFetchingCities = useSelector(
    (state) => state.mosqueReducer.isFetchingCities
  );
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const activeMember = useSelector((state) => state.mosqueReducer.activeMember);
  const customer = useSelector(
    (state) => state.mosqueReducer.memberDetails?.users
  );
  const isUpdatedSuccessfully = useSelector(
    (state) => state.mosqueReducer.memberDetails?.isUpdatedSuccessfully
  );
  const isApprovalDecline = useSelector(
    (state) => state.mosqueReducer.isApprovalDecline
  );
  const isApprovedApproval = useSelector(
    (state) => state.mosqueReducer.isApprovedApproval
  );
  const memberPlans = useSelector((state) => state.mosqueReducer.memberPlans);
  const plan_list = memberPlans?.planList;

  const [errors, setErrors] = useState({});
  const location = useLocation();
  const item = location.state;
  const [isToBeApproved, setIsToBeApproved] = useState(false);
  const [users, setUserData] = useState({
    contact_name: "",
    contact_email: "",
    country_code: "+31",
    contact_phone: "",
    contact_country: "",
    contact_city: "",
    contact_address: "",
    contact_pincode: "",
    subscription_plan_id: "",
    entity_id: "",
    subscription_id: "",
    customer_acc_name: "",
    customer_iban: "",
    paymentMode: "",
    age: "",
    dob: "",
    gender: "",
    //membership_date: new Date().toISOString(),
  });
  /*const [formData, setFormData] = useState({
    name: activeMemberDetails?.name ,
    emailAddress: activeMemberDetails?.emailAddress || '',
    phoneNumber: activeMemberDetails?.phoneNumber || '',
    countryCode: activeMemberDetails?.countryCode || '',
    subscription_plan_id: activeMemberDetails?.subscriptions?.[0]?.subscriptionId || ''
  });

  useEffect(() => {
    if (isActiveEdit && details) {
      setFormData({
        name: details.customer.contact_name || '',
        emailAddress: details.customer.contact_email || '',
        phoneNumber: details.customer.contact_phone || '',
        countryCode: details.customer.country_code || '',
        subscription_plan_id: details.subscriptions?.[0]?.subscription_plan_id || ''
      });
    }
  }, [isActiveEdit, details]); */

  // useEffect(() => {
  //   if (details) {
  //     const data = {};
  //     data.contact_name = details?.customer?.contact_name;
  //     data.contact_email = details?.customer?.contact_email;
  //     data.country_code = details?.customer?.country_code;
  //     data.contact_phone = details?.customer?.contact_phone;
  //     data.contact_country = details?.customer?.contact_country;
  //     data.contact_city = details?.customer?.contact_city;
  //     data.contact_address = details?.customer?.contact_address;
  //     data.contact_pincode = details?.customer?.contact_pincode;
  //     data.subscription_plan_id =
  //       details?.subscriptions?.[0]?.subscription_plan_id;
  //     data.entity_id = details?.subscriptions?.[0]?.entity_id;
  //     data.customer_acc_name = details?.customer?.customer_acc_name;
  //     data.customer_iban = details?.customer?.customer_iban;
  //     data.paymentMode = details?.customer?.paymentMode;
  //     data.subscription_id = details?.subscriptions?.[0]?.subscription_id;
  //     //data.membership_date = details?.subscriptions?.[0]?.member_since;

  //     setUserData(data);
  //     setIsFreeSubscription(data.subscription_plan_id === "free");
  //     setIsManualPay(data.paymentMode === "manual");
  //   } else {
  //     setUserData({
  //       contact_name: "",
  //       contact_email: "",
  //       country_code: "+31",
  //       contact_phone: "",
  //       contact_country: "",
  //       contact_city: "",
  //       contact_address: "",
  //       contact_pincode: "",
  //       subscription_plan_id: "",
  //       entity_id: "",
  //       subscription_id: "",
  //       customer_acc_name: "",
  //       customer_iban: "",
  //       paymentMode: "",
  //       //membership_date: new Date().toISOString(),
  //     });
  //     setIsFreeSubscription(false);
  //     setIsManualPay(false);
  //   }
  // }, [details]);

  useEffect(() => {
    setIsManualPay(users.paymentMode === "manual");
  }, [users.paymentMode]);

  useEffect(() => {
    if (users) {
      handleEmailAndPhoneValidation();
    }
  }, [users]);

  /*useEffect(() => {
    if (isApprovalDecline) {
      setTimeout(() => {
        window.location.href = `/mosque/${activeEntity?._id}/members/add`;
      }, 1000)
    }
  }, [isApprovalDecline]);*/

  const handleChangeUser = (keyName, keyValue) => {
    let update = { ...users };
    if (keyName === "contact_email") {
      keyValue = keyValue.toLowerCase();
    }
    
    if (keyName === "subscription_plan_id") {
      const selectedPlan = plan_list.filter(
        (item) => item._id === keyValue
      )?.[0];
      update.subscription_id = keyValue;
      console.log("sub_id : ", update.subscription_id);
      setIsFreeSubscription(keyValue === "free");
      setIsManualPay(keyValue === "manual");
      if (keyValue === "free") {
        update.customer_acc_name = "";
        update.customer_iban = "";
        update.paymentMode = "";
      }
    }
    if (keyName === "paymentMode") {
      if (keyValue === "manual") {
        update.customer_acc_name = "";
        update.customer_iban = "";
      }
      if (keyValue === null) {
        update.subscription_plan_id = "free";
        update.subscription_id = "free";
      }
      if (keyValue === "automatic") {
        update.subscription_plan_id = null;
        update.subscription_id = null;
      }
    }
    /*if (keyName === "membership_date") {
        update["membership_date"] = new Date(keyValue?.$d).toISOString();
    }*/
    if (
      keyName === "contact_country" &&
      keyValue !== update["contact_country"]
    ) {
      update["contact_city"] = "";
    }
    update[keyName] = keyValue;
    setUserData(update);
  };

  const optionsPlan = (paymentMode) => {
    let listValues = [];
    if (plan_list?.length) {
      plan_list.forEach((plan) => {
        if (plan.isFree) {
          // Check for a 'isFree' property in the plan object
          // Don't push the free plan to listValues
          return;
        }
        listValues.push({
          ...plan,
          label: `${plan.plan_name} - € ${plan.amount}`,
          value: plan._id,
        });
      });
    }
    if (paymentMode === null) {
      listValues.unshift({
        label: t("Gratis abonnement"),
        value: "free", //
      });
    }
    return listValues;
  };

  const handleSubmit = () => {
    const { isValid, error } = handleValidation();
    if (!isValid) {
      setErrors(error);
    } else {
      // Form is valid, proceed with form submission
      SubmitMember();
    }
  };
  const calculateAge = () => {
    const dobDate = new Date(users.dob);
    const today = new Date();
    let cAge = today.getFullYear() - dobDate.getFullYear();
    const hasHadBirthday =
      today.getMonth() > dobDate.getMonth() ||
      (today.getMonth() === dobDate.getMonth() &&
        today.getDate() >= dobDate.getDate());

    if (!hasHadBirthday) {
      cAge--;
    }

    return cAge;
  };
  const SubmitMember = async () => {
    let update = { ...users };
    update.entity_id = activeEntity?._id;
    update.created_by_admin = true;
    update.age = calculateAge();
    if (update.subscription_plan_id === "free") {
      console.log(
        "Setting subscription_plan_id to before null : ",
        update.subscription_plan_id
      );
      update.subscription_plan_id = null;
      update.subscription_id = null;
      console.log(
        "Setting subscription_plan_id to after null : ",
        update.subscription_plan_id
      );
    }

    if (!update.contact_address) {
      update.contact_address = null;
    }

    if (!update.customer_acc_name) {
      update.customer_acc_name = null;
    }

    if (!update.customer_iban) {
      update.customer_iban = null;
    }

    if (!update.contact_pincode) {
      update.contact_pincode = null;
    }

    if (!update.paymentMode) {
      update.paymentMode = null;
    }

    const trimmedDetails = {};
    // Trim each attribute and assign it to trimmedDetails object
    for (const key in update) {
      if (typeof update[key] === "string") {
        trimmedDetails[key] = update[key].trim();
      } else {
        trimmedDetails[key] = update[key];
      }
    }

    console.log("trimmed ", trimmedDetails);

    const res = await callApiV2({
      method: "post",
      endpoint: `onboarding/new-member-signup`,
      body: {
        users: trimmedDetails,
      },
      isErrorSuppressed: true,
    });
    if (res?.status === "Success") {
      setIsAddedNew(true);
      showNotification("success", "Nieuwe gebruiker succesvol aangemaakt.");
      setUserData({
        contact_name: "",
        contact_email: "",
        country_code: "+31",
        contact_phone: "",
        contact_country: "",
        contact_city: "",
        contact_address: "",
        contact_pincode: "",
        subscription_plan_id: "",
        //membership_date: "",
        entity_id: "",
        subscription_id: "",
        customer_acc_name: "",
        customer_iban: "",
        paymentMode: "",
        age: "",
        dob: "",
        gender: "",
      });
    } else {
      showNotification(
        "error",
        res?.data?.message || "Something went wrong..."
      );
    }
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!users.contact_name) {
      isValid = false;
      error["contact_name"] = "Dit veld is verplicht";
    }
    // if (!users.age) {
    //   isValid = false;
    //   error["age"] = "Dit veld is verplicht";
    // }
    // if (!users.dob) {
    //   isValid = false;
    //   error["dob"] = "Dit veld is verplicht";
    // }
    // if (!users.gender) {
    //   isValid = false;
    //   error["gender"] = "Dit veld is verplicht";
    // }
    if (!users.contact_email || !isEmail(users.contact_email)) {
      isValid = false;
      error["contact_email"] = "Voer alstublieft een geldig e-mailadres in";
    }
    if (!users.contact_phone) {
      // && !phoneNumberRegex.test(users.contact_phone.replace(/[-()\s]/g, ""))
      isValid = false;
      error["contact_phone"] = "Dit veld is verplicht";
    }
    if (
      users.contact_phone &&
      (users.contact_phone.length<4 || users.contact_phone.length>13 )
    ) {
      isValid = false;
      error["contact_phone"] =
      t("Please enter a valid phone number");
    }
    if (!users.contact_country) {
      isValid = false;
      error["contact_country"] = "Dit veld is verplicht";
    }
    if (!users.contact_city) {
      isValid = false;
      error["contact_city"] = "Dit veld is verplicht";
    }
    if (!users.subscription_plan_id) {
      isValid = false;
      error["subscription_plan_id"] = "Dit veld is verplicht";
    }
    if (!users.paymentMode && users.paymentMode !== null) {
      isValid = false;
      error["paymentMode"] = "Dit veld is verplicht";
    }
    if (
      users.subscription_plan_id &&
      users.subscription_plan_id !== "free" &&
      !users.paymentMode
    ) {
      isValid = false;
      error["paymentMode"] =
        "Selecteer alstublieft de betalingswijze als u een lidmaatschap heeft geselecteerd";
    }
    if (
      users.subscription_plan_id !== "free" &&
      users.customer_acc_name &&
      users.customer_iban &&
      !users.paymentMode
    ) {
      isValid = false;
      error["paymentMode"] =
        "Selecteer alstublieft betalingsmethode als automatisch";
    }
    if (
      users.customer_acc_name &&
      !users.subscription_plan_id &&
      users.subscription_plan_id !== "free"
    ) {
      isValid = false;
      error["customer_acc_name"] = "Selecteer alstublieft een lidmaatschap";
    }
    if (
      users.customer_iban &&
      !users.subscription_plan_id &&
      users.subscription_plan_id !== "free"
    ) {
      isValid = false;
      error["customer_iban"] = "Selecteer alstublieft een lidmaatschap";
    }
    if (
      users.subscription_plan_id !== "free" &&
      users.paymentMode === "automatic" &&
      !users.customer_acc_name
    ) {
      isValid = false;
      error["customer_acc_name"] =
        "Voer alstublieft de naam van de rekeninghouder in";
    }
    if (
      users.subscription_plan_id !== "free" &&
      users.paymentMode === "automatic" &&
      !users.customer_iban
    ) {
      isValid = false;
      error["customer_iban"] = "Voer alstublieft de IBAN van de gebruiker in";
    }
    if (
      users.customer_acc_name &&
      users.customer_iban &&
      users.subscription_plan_id === "free"
    ) {
      isValid = false;
      error["subscription_plan_id"] = "Selecteer alstublieft een lidmaatschap";
    }
    if (users?.paymentMode === "manual" && !users.subscription_plan_id) {
      isValid = false;
      error["paymentMode"] = "Selecteer alstublieft een lidmaatschap";
    }
    return {
      isValid,
      error,
    };
  };

  const handleEmailAndPhoneValidation = () => {
    setErrors({});
    //let validate = handleValidation();
    /*if (!validate.isValid) {
      setErrors(validate.error);
      setIsNext(false);
    }
    else */ if (
      !!users?.contact_name?.trim() &&
      !!users?.contact_email?.trim() &&
      !!users?.contact_phone?.trim() &&
      !!users?.contact_country?.trim() &&
      !!users?.contact_city?.trim() &&
      !!users?.subscription_plan_id?.trim()
    ) {
      setIsNext(true);
    }
  };

  const handleButtonClick = () => {
    setIsAddedNew(false);
    window.location.href = `/mosque/${activeEntity?._id}/members/add`;
  };

  const handleRedirection = () => {
    setIsAddedNew(false);
    window.location.href = `/mosque/${activeEntity?._id}/info`;
  };

  const handleApproval = () => {
    dispatch({
      type: UPDATE_APPROVAL_STATUS,
      isApprovedApproval: false,
    });
    window.location.href = `/mosque/${activeEntity?._id}/info`;
  };

  const handleClickDecline = () => {
    if (approvalId && activeEntity?._id) {
      dispatch(memberDecline(approvalId, activeEntity?._id))
      .then(() => {
        setIsDecline(false);
        setTimeout(() => {
          window.location.href = `/mosque/${activeEntity?._id}/dashboard`;
        }, 1500);
      })
      .catch((error) => {
        toast.error(error)
        console.error("Error declining member:", error);
      });
    }
  };

  const handleClickApproval = () => {
    if (memberSubId && approvalId) {
      dispatch(memberApprove(memberSubId, approvalId, users.user_id))
      // .then(()=>{window.location.href = `/mosque/${activeEntity?._id}/info`})
      // .catch(err=> toast.error(err))
      
    }
  };
  useEffect(() => {
    const getUserData = async () => {
      try {
        const baseUrl =
          process.env.REACT_APP_STAGE === "development"
            ? "apidev.mosqapp.com"
            : "api.mosqapp.com";
        const res = await fetch(
          `https://${baseUrl}/onboarding/mosque/${item?.entity_id}/member/${item?.ref_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const json = await res.json();
        details = json;
        console.log("user->", json);
        if (details?.name) setIsToBeApproved(true);
        if (details) {
          const data = {};
          data.contact_name = details?.name;
          data.contact_email = details?.emailAddress;
          data.country_code = details?.countryCode || "+31";
          data.contact_phone = details?.phoneNumber;
          data.contact_country = details?.country;
          data.contact_city = details?.city;
          data.contact_address = details?.contact_address;
          data.contact_pincode = details?.contact_pincode;
          if (details?.emailAddress)
            data.subscription_plan_id =
              details?.subscriptions?.[0]?.subscriptionId ||
              "Gratis abonnement";
          data.entity_id = details?.subscriptions?.[0]?.entityId;
          data.customer_acc_name = details?.customerAccountName;
          data.customer_iban = details?.customerIBAN;
          if (details?.emailAddress)
            data.paymentMode = details?.paymentMode || "Gratis abonnement";
          data.subscription_id = details?.subscriptions?.[0]?.subscriptionId;
          data.age = details?.age;
          data.gender = details?.gender;
          data.dob = details?.dob;
          data.user_id = details._id;
          //data.membership_date = details?.subscriptions?.[0]?.member_since;

          setUserData(data);
          setIsFreeSubscription(data.subscription_plan_id === "free");
          setIsManualPay(data.paymentMode === "manual");
          console.log(data);
        } else {
          setUserData({
            contact_name: "",
            contact_email: "",
            country_code: "+31",
            contact_phone: "",
            contact_country: "",
            contact_city: "",
            contact_address: "",
            contact_pincode: "",
            subscription_plan_id: "",
            entity_id: "",
            subscription_id: "",
            customer_acc_name: "",
            customer_iban: "",
            paymentMode: "",
            age: "",
            gender: "",
            dob: "",
            //membership_date: new Date().toISOString(),
          });
          setIsFreeSubscription(false);
          setIsManualPay(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUserData();
  }, []);
  console.log(process.env.REACT_APP_STAGE);
  console.log("users", users);
  const getRenderButton = () => {
    if (details) {
      return (
        <Box display="flex" justifyContent="center">
          <Box>
            <Button
              type="outlineColor"
              text={t("Decline Member")}
              borderRadius="66px"
              minWidth="200px"
              mt="4"
              mx="auto"
              height="50px"
              onClick={() => setIsDecline(true)}
            />
          </Box>
          <Box ml="15px">
            <Button
              type="primary"
              text={t("Approve Member")}
              borderRadius="66px"
              minWidth="200px"
              mt="4"
              mx="auto"
              height="50px"
              onClick={() => handleClickApproval()}
            />
          </Box>
        </Box>
      );
    } else {
      return (
        <Button
          //type={!isNext ? "disabled" : "primary"}
          type="primary"
          text={t("Gegevens opslaan")}
          borderRadius="66px"
          width="15%"
          mt="4"
          mx="auto"
          style={{
            fontSize: "18px",
            fontWeight: 800,
            fontFamily: "Raleway",
            minWidth: "188px",
          }}
          height="60px"
          onClick={handleSubmit}
        />
      );
    }
  };
  console.log("isTobeapproved -->", isToBeApproved);
  return (
    <div className="add_form form_width">
      <div className="mosque-form">
        <h2 className="headingTwo">{t("Persoonlijke Gegevens")}</h2>
        <div className="row">
          <div className="col-lg-6 input-width add-input-field-margin">
            <label mb="8px" isMandatory>
              {t("Volledige naam")} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={users?.contact_name}
              onChange={(e) => handleChangeUser("contact_name", e.target.value)}
              type="text"
              placeholder={t("Voer naam in")}
              className="input-box"
              disabled={isToBeApproved}
            />
            {errors.contact_name && (
              <ErrorMessage>{t(errors.contact_name)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 input-width add-input-field-margin">
            <label mb="8px" isMandatory>
              {t("E-mailadres")} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              onBlur={() => handleEmailAndPhoneValidation()}
              value={users?.contact_email}
              onChange={(e) =>
                handleChangeUser("contact_email", e.target.value)
              }
              type="text"
              placeholder={t("Voer uw E-mailadres In")}
              className="input-box"
              disabled={isToBeApproved}
            />
            {errors.contact_email && (
              <ErrorMessage>{t(errors.contact_email)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="8px" isMandatory>
              {t("Contactnummer")} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              onBlur={() => handleEmailAndPhoneValidation()}
              value={users?.contact_phone}
              countryCodes={true}
              countryCodeValue={users?.country_code}
              setCountryCode={(e) => handleChangeUser("country_code", e)}
              isDisabled={isToBeApproved}
              onChange={(e) =>
                handleChangeUser("contact_phone", e.target.value)
              }
              border={users?.contact_phone?.length ? "1px solid #606734" : null}
              placeholder="785-785-464"
              className="input-box"
              type="number"
              pattern="[0-9]"
              height="52px"
              overflow="unset"
            />
            {errors.contact_phone && (
              <ErrorMessage>{t(errors.contact_phone)}</ErrorMessage>
            )}
          </div>

          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            {!countries?.length ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <label mb="8px" isMandatory>
                  {t("Land van gebruiker")}{" "}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <Dropdown
                  allowSearch
                  variant="outlinedGreen"
                  defaultText={users?.contact_country || t("Selecteer Land")}
                  color="#1a1a1aad"
                  fontSize="18px"
                  fontWeight="500"
                  width="100%"
                  height="54px"
                  Icon="none"
                  selectedValue={users?.contact_country}
                  onOptionClick={(item) => {
                    handleChangeUser("contact_country", item?.value);
                    // handleChangeUser("contact_city", "");
                    dispatch({
                      type: STORE_CITIES,
                      cities: [],
                    });
                  }}
                  options={customCountries}
                  disabled={isToBeApproved}
                />
                {errors.contact_country && (
                  <ErrorMessage>{t(errors.contact_country)}</ErrorMessage>
                )}
              </>
            )}
          </div>

          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="8px">{t("Postcode")}</label>
            <input
              value={users?.contact_pincode}
              onChange={(e) =>
                handleChangeUser("contact_pincode", e.target.value)
              }
              type="text"
              placeholder={t("Voer postcode in")}
              className="input-box"
              disabled={isToBeApproved}
            />
          </div>
          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            {isFetchingCities ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <label mb="8px" isMandatory>
                  {t("Stad van gebruiker")}{" "}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                {/* <Dropdown
              allowSearch
              variant="outlinedGreen"
              defaultText={users?.contact_city || t("Selecteer stad")}
              color="#1A1A1A"
              fontSize="18px"
              fontWeight="400"
              width="100%"
              height="54px"
              Icon="none"
              noResultIcon=""
              selectedValue={users?.contact_city}
              onOptionClick={(item) =>
                handleChangeUser("contact_city", item?.value)
              }
              options={cities}
              disabled={isToBeApproved}
            /> */}
                <CitySelectUser
                  defaultText={t("Selecteer stad")}
                  handleChangeUser={handleChangeUser}
                  users={users}
                  cities={cities}
                />
                {errors.contact_city && (
                  <ErrorMessage>{t(errors.contact_city)}</ErrorMessage>
                )}
              </>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="8px">{t("Adres gebruiker")}</label>
            <input
              value={users?.contact_address}
              onChange={(e) =>
                handleChangeUser("contact_address", e.target.value)
              }
              type="text"
              placeholder={t("Voer adres gebruiker in")}
              className="input-box"
              disabled={isToBeApproved}
            />
          </div>

          {/* <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="8px">{t('Age')} <span style={{ color: "#FE1717" }}>*</span></label>
            <input
              type="number"
              value={users?.age || ''}
              onChange={(e) => handleChangeUser("age", e.target.value)}
              placeholder={t("Enter age")}
              className="input-box"
              disabled={isToBeApproved}
              min="0" 
              max="120" 
            />
            {errors.age && (
              <ErrorMessage>{t(errors.age)}</ErrorMessage>
            )}
          </div> */}
          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="8px">
              {t("Date of Birth")}
            </label>
            <input
              type="date"
              value={users?.dob || ""}
              onChange={(e) => handleChangeUser("dob", e.target.value)}
              className="input-box calendar"
              disabled={isToBeApproved}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() - 18))
                  .toISOString()
                  .split("T")[0]
              }
              min={
                new Date(new Date().setFullYear(new Date().getFullYear() - 100))
                  .toISOString()
                  .split("T")[0]
              }
            />
            {errors.dob && <ErrorMessage>{t(errors.dob)}</ErrorMessage>}
          </div>

          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="28px">
              {t("Gender")}
            </label>
            <div
              mt="20px"
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <label
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  checked={users?.gender === "male"}
                  onChange={(e) => handleChangeUser("gender", e.target.value)}
                  disabled={isToBeApproved}
                />
                {t("Male")}
              </label>
              <label
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={users?.gender === "female"}
                  onChange={(e) => handleChangeUser("gender", e.target.value)}
                  disabled={isToBeApproved}
                />
                {t("Female")}
              </label>
            </div>
            {errors.gender && <ErrorMessage>{t(errors.gender)}</ErrorMessage>}
          </div>
        </div>

        <h2 className="headingTwo" style={{ marginTop: "20px" }}>
          {t("Betalingsgegevens")}
        </h2>

        <div
          className="row"
          style={{ pointerEvents: isToBeApproved ? "none" : "" }}
        >
          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="8px">
              {t("Lidmaatschap")} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Select
              size="middle"
              style={{
                fontFamily: "Raleway",
                fontWeight: 400,
                fontSize: "18px",
                color: "#1A1A1A",
                width: "100%",
              }}
              disabled={users?.paymentMode === null}
              value={users?.subscription_plan_id || t("Selecteer Plan")}
              onChange={(value) =>
                handleChangeUser("subscription_plan_id", value)
              }
              options={optionsPlan(users?.paymentMode)}
            />
            <div style={{ marginTop: "20px" }}>
              {errors.subscription_plan_id && (
                <ErrorMessage>{t(errors.subscription_plan_id)}</ErrorMessage>
              )}
            </div>
          </div>

          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="8px">
              {t("Betalingswijze")} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Select
              size={"middle"}
              className="dropdown-style"
              value={
                users?.paymentMode ||
                (users?.paymentMode === null
                  ? t("Gratis abonnement")
                  : t("Selecteer type"))
              }
              defaultValue={t("Select type")}
              onChange={(value) => {
                handleChangeUser("paymentMode", value);
              }}
              style={{ width: "100%", height: "54px" }}
              options={paymentMethodOptions}
            />
            {errors.paymentMode && (
              <ErrorMessage>{t(errors.paymentMode)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="8px">
              {t("Naam rekeninghouder")}{" "}
              {users.paymentMode === "automatic" && (
                <span style={{ color: "#FE1717" }}>*</span>
              )}
            </label>
            <input
              value={users?.customer_acc_name}
              onChange={(e) =>
                handleChangeUser("customer_acc_name", e.target.value)
              }
              type="text"
              placeholder={t("Voer de naam van de rekeninghouder in")}
              className="input-box"
              disabled={users?.paymentMode === null}
            />
            {errors.customer_acc_name && (
              <ErrorMessage>{t(errors.customer_acc_name)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="8px">
              {t("IBAN van gebruiker")}{" "}
              {users.paymentMode === "automatic" && (
                <span style={{ color: "#FE1717" }}>*</span>
              )}
            </label>
            <input
              value={users?.customer_iban}
              onChange={(e) =>
                handleChangeUser(
                  "customer_iban",
                  e.target.value?.replace(/[^0-9a-zA-Z]/g, "")
                )
              }
              type="text"
              placeholder={t("Voer de IBAN van de gebruiker in")}
              className="input-box"
              disabled={users?.paymentMode === null}
            />
            {errors.customer_iban && (
              <ErrorMessage>{t(errors.customer_iban)}</ErrorMessage>
            )}
          </div>
          {/*<div className="col-lg-6 pt-2 mb-4 input-width">
            <label mb="8px" isMandatory>
            Lidmaatschapsdatum
            </label>
            <DatePickerBox>
              <DatePicker
                defaultValue={dayjs()}
                value={dayjs(users?.membership_date, "YYYY-MM-DD")}
                onChange={(value) => handleChangeUser("membership_date", value)}
              />
            </DatePickerBox>
            {errors.membership_date && (
              <ErrorMessage>{errors.membership_date}</ErrorMessage>
            )}
            </div>*/}
        </div>
        {getRenderButton()}
        <br />
      </div>
      <DefaultModal
        show={isAddedNew}
        onHide={() => setIsAddedNew(false)}
        className="add-mosque-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div>
          <Icon
            iconName="modalClose"
            iconSize="xs"
            style={{ marginLeft: "auto" }}
            onClick={() => handleRedirection()}
          />
          <div className="text-center add-mosque-modal-content">
            <Icon
              iconName="congratulations"
              style={{ justifyContent: "center" }}
            />
            <div>{t("Gefeliciteerd")}</div>
            <div style={{ textAlign: "center" }}>
              {t("We hebben de gegevens van de nieuwe gebruiker toegevoegd.")}
            </div>
            <Box
              display="flex"
              justifyContent="center"
              onClick={() => handleButtonClick()}
            >
              <Button width="40px">{t("Voeg nieuw lid toe")}</Button>
            </Box>
          </div>
        </div>
      </DefaultModal>

      <DefaultModal
        show={isApprovedApproval}
        onHide={() => handleApproval()}
        className="add-mosque-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div>
          <Icon
            iconName="modalClose"
            iconSize="xs"
            style={{ marginLeft: "auto" }}
            onClick={() => handleApproval()}
          />
          <div className="text-center">
            <Icon
              iconName="congratulations"
              style={{ justifyContent: "center" }}
            />
            <Label
              justifyContent="center"
              fontSize="20px"
              fontWeight={700}
              my="20px"
            >
              {t("Congratulations!")}
            </Label>
            <Label
              variant="text3"
              my="20px"
              color="#7E7E7E"
              justifyContent="center"
            >
              {t("We have added the new member.")}
            </Label>
            <Button
                minWidth="200px !important"
                width="200px !important"
                height="45px !important"
                justifyContent="center"
                onClick={() => handleApproval()}
                margin="0 auto !important"
              >
                {t("View Member")}
              </Button>
          </div>
        </div>
      </DefaultModal>

      <DefaultModal
        show={isDecline}
        onHide={() => setIsDecline(false)}
        className="add-mosque-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div>
          <Icon
            iconName="modalClose"
            iconSize="xs"
            style={{ marginLeft: "auto" }}
            onClick={() => setIsDecline(false)}
          />
          <div className="text-center">
            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
            <Label
              justifyContent="center"
              fontSize="20px"
              fontWeight={700}
              my="20px"
            >
              {t("Decline Member")}
            </Label>
            <Label variant="text3" my="20px" color="#7E7E7E">
              {t(
                "You will permanently loose this member’s details and other information."
              )}
            </Label>
            <Box className="test" display="flex" justifyContent="center">
              <Button
                className="test"
                type="outlineColor"
                minWidth="200px !important"
                width="200px !important"
                height="45px !important"
                mr="20px !important"
                onClick={() => setIsDecline(false)}
              >
                {t("Cancel")}
              </Button>
              <Button
                minWidth="200px !important"
                width="200px !important"
                height="45px !important"
                mr="20px !important"
                onClick={() => handleClickDecline()}
              >
                {t("Decline Member")}
              </Button>
            </Box>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default AddForm;
