import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons/Button";
import {
  agentLogin,
  validateOtpForAgent, setOtpSent,
} from "../../../../Mosque/mosqueAction";
import truncate from "lodash/truncate";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Label from "../../../../../components/Label";
import Box from "../../../../../components/Box";
import { VALIDATE_OTP } from "../../../../Mosque/mosqueActionType";
import { useTranslation } from "react-i18next";
import { color } from "styled-system";
import { Link } from "react-router-dom";
import axios from "axios";
import { showNotification } from "../../../../../util/Utility";
import { APP_TOKEN,USER_ID,USER_TYPE, USER_TYPE_MOSQUE } from "../../../../../constants/appEnums";
import jwt_decode from "jwt-decode";
import { setAgent } from "../../../../Mosque/mosqueAction";
import { setAuthState, setUserId } from "../../../../appAction";
import { AUTHENTICATED } from "../../../../../constants/authEnums";
import { VALIDATE_OTP_SUCCESS } from "../../../../Mosque/mosqueActionType";

const AgentNotFountText = styled.div`
  color: #fe1717;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
`;
const GeneralLink = styled(Link)`
  text-decoration: unset;
`;

const MosqueLogin = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  let timer;
  const sendOtpDetail = useSelector(
    (state) => state?.customerReducer?.signUp_data
  );

  const is_agent_found = useSelector(
    (state) => state?.mosqueReducer?.is_agent_found
  );

  const isOtpSent = useSelector((state) => state?.mosqueReducer?.isOtpSent);
  const otp_error = useSelector((state) => state?.mosqueReducer?.otp_error);

  const user_id = useSelector(
    (state) => state?.customerReducer?.validate_data?.customer?._id
  );
  const isSentOtp = sendOtpDetail?.is_otp_sent;

  const [modalView, setModalView] = useState("login-page");

  useEffect(() => {
    if (isOtpSent) {
      setModalView("otp-page");
    } else {
      setModalView("login-page");
    }
  });

  const [user, setUserData] = useState({
    contact_email: "",
    password:""
  });

  const [seconds, setSeconds] = useState("60");

  const [minutes, setMinutes] = useState("0");
  const [timeLeft, setTimeLeft] = useState(90);
  const [otpSent, setOtpSent] = useState(false);
  const timerRef = useRef(null);


  const [otpInput, setOtpInput] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
  });

  const [errors, setErrors] = useState({});

  const firstInput = useRef();
  const secondInput = useRef();
  const thirdInput = useRef();
  const forthInput = useRef();

  const handleOnChange = (keyName, keyValue) => {
    dispatch({
      type: VALIDATE_OTP,
      isFetching: true,
    });
    const maxLength = 1; // Set the desired maximum length

    const otpDetails = {...otpInput};

    if (keyValue.length > maxLength) {
      return null;
    } else {
      otpDetails[keyName] = keyValue
      setOtpInput(otpDetails)
    }
  }
  const startTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const resetTimer = () => {
    setTimeLeft(90);
    setOtpSent((prev)=>!prev);
  };

  useEffect(() => {
    startTimer();
    return () => clearInterval(timerRef.current);
  }, [otpSent]);

  useEffect(() => {
    if (user_id) {
      setModalView("success-page");
    }
  }, [user_id]);

  useEffect(() => {
    if (isSentOtp) {
      setModalView("otp-page");
    }
  }, [isSentOtp]);

  const handleChangeUser = (keyName, keyValue) => {
    setErrors({});
    if( keyName === "contact_email" ){
        keyValue = keyValue.toLowerCase();
    }
    let update = { ...user };
    update[keyName] = keyValue;
    setUserData(update);
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!user.contact_email.trim() || !isEmail(user.contact_email.trim())) {
      isValid = false;
      error["contact_email"] = "Please enter valid email address";
    }
    return {
      isValid,
      error
    };
  }

  const sendOtpHandle = async (isResend) => {
    const body = {
      contact_email: user?.contact_email,
      password: user?.password,
    };

    const trimmedDetails = {};
    for (const key in body) {
      if (typeof body[key] === "string") {
        trimmedDetails[key] = body[key].trim();
      } else {
        trimmedDetails[key] = body[key];
      }
    }

    if (trimmedDetails) {
      try {
        // const baseUrl = 'http://localhost:8070';
        const baseUrl =
      process.env.REACT_APP_STAGE === 'development'
        ? 'apidev.mosqapp.com'
        : 'api.mosqapp.com';
        const res = await axios.post(`https://${baseUrl}/agent/auth/login`, body);
        if (res?.data?.status === "Success") {
          const { token } = res?.data?.data;
          console.log(res.data);
          
          window.localStorage.setItem(APP_TOKEN, token);
          window.localStorage.setItem(USER_TYPE, USER_TYPE_MOSQUE);
          window.localStorage.setItem(USER_ID, res?.data?.data?.agent?._id);
          const tempUser = jwt_decode(token);
          dispatch(setAgent(tempUser));
          dispatch(setAuthState(AUTHENTICATED));
    
          showNotification(
            "success",
            "Welkom bij het beheerdersdashboard van Mosqapp."
          );
          dispatch({
            type: VALIDATE_OTP_SUCCESS,
            payload: res?.data?.data,
            isValidate: true,
            isFetching: false,
          });
    
          if (res?.data?.data?.agent?._id) {
            setUserId(res?.data?.data?.agent?._id);
          }
    
          window.location = `/mosque/${res?.data?.data?.agent?.entity?._id}/dashboard`;
        }

      } catch (error) {
        console.log(error);
        
        showNotification(
          "error",
          error?.data?.message?.message ||
            error?.data?.message ||
            "Er is iets misgegaan..."
        );
      }
    }
  };

  const submitOtpHandle = () => {
    const body = {
      contact_email: user?.contact_email,
      local_otp:
        otpInput.input1 + otpInput.input2 + otpInput.input3 + otpInput.input4,
    };

    if (body) {
      dispatch(validateOtpForAgent(body));
    }
  };

  const handleClickRegister = () => {
    window.location = "/?register=true";
  };

  const onClickHandle = () => {
    dispatch(setOtpSent(false));
    setModalView("login-page")
  }

  return (
    <div className="agent-login" >
      {/* <h4> {t('Inloggen')}</h4> */}
      {modalView === "login-page" ? (
        <div>
          <Label variant="text5" fontWeight={500} mt="24px" mb="12px">{t('Inloggen ID')}<span style={{ color: "#FE1717" }}>*</span></Label>
          <Input
            value={user.contact_email}
            onChange={(e) => handleChangeUser("contact_email", e.target.value)}
            border={user?.contact_email?.length ? "1px solid #606734" : null}
            placeholder={t("Voer uw E-mailadres In")}
            className="input-box"
            type="text"
            height="46px"
          />
          {errors.contact_email && <ErrorMessage>{t(errors.contact_email)}</ErrorMessage>}
          {is_agent_found && (
            <ErrorMessage>
                {t('Dit e-mailadres is niet bij ons geregistreerd.')}
            </ErrorMessage>
          )}
          <Label variant="text5" fontWeight={500} mt="24px" mb="12px">
          {t('Wachtwoord')}<span style={{ color: "#FE1717" }}>*</span>
          </Label>
          <Input
            value={user.password}
            onChange={(e) => handleChangeUser("password", e.target.value)}
            border="1px solid #EFF0F7"
            boxShadow="0px 2px 6px #13124212"
            borderRadius="8px"
            placeholder={t("Voer Wachtwoord")}
            className="input-box"
            type="text"
            isEyeButton
          />
          <GeneralLink to="/agent/forget">
          <Label
            variant="text8"
            fontWeight={500}
            color="#D0004B"
            justifyContent="flex-end"
            mt="12px"
          >
           {t('Wachtwoord vergeten?')}
          </Label>
        </GeneralLink>
          <div className="d-flex justify-content-center mt-4 mb-4">
            <Button
              bg={user.contact_email ? "#D0004B" : "#A0AEC0"}
              text={t('Nu inloggen')}
              disabled={user.contact_email ? "" : "true"}
              onClick={() => sendOtpHandle(false)}
            />
          </div>
        </div>
      ) : null}
      {/* otp-page */}
      {modalView === "otp-page" ? (
        <>
          <p className="d-flex justify-content-between">
          {t('Uw e-mailadres')}  <b>{truncate(user.contact_email, { length: 23 })}</b>
            {/* <div
              onClick={() => onClickHandle()}
              style={{ cursor: "pointer" }}
            >
              <img src="https://ik.imagekit.io/rzlzf40okya/elixir/edit.svg" />
            </div> */}
          </p>
          <Label mb="8px">{t('Voer OTP in')}</Label>
          <div className="otp-bx">
            <input
              ref={firstInput}
              className={otp_error ? "otp-input otp-err" : "otp-input"}
              type="number"
              maxLength="1"
              value={otpInput.input1}
              onChange={(e) => {
                handleOnChange("input1", e.target.value)
                e.target.value && secondInput.current.focus();
              }}
            />
            <input
              ref={secondInput}
              className={otp_error ? "otp-input otp-err" : "otp-input"}
              type="number"
              maxLength="1"
              value={otpInput.input2}
              onChange={(e) => {
                handleOnChange("input2", e.target.value)
                !e.target.value
                  ? firstInput.current.focus()
                  : thirdInput.current.focus();
              }}
            />
            <input
              ref={thirdInput}
              className={otp_error ? "otp-input otp-err" : "otp-input"}
              type="number"
              maxLength="1"
              value={otpInput.input3}
              onChange={(e) => {
                handleOnChange("input3", e.target.value)
                !e.target.value
                  ? secondInput.current.focus()
                  : forthInput.current.focus();
              }}
            />
            <input
              ref={forthInput}
              className={otp_error ? "otp-input otp-err" : "otp-input"}
              type="number"
              maxLength="1"
              value={otpInput.input4}
              onChange={(e) => {
                handleOnChange("input4", e.target.value)
                !e.target.value && thirdInput.current.focus();
              }}
            />
          </div>
          <div className="TimerBody">
            <div>
            {`${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? '0' : ''}${timeLeft % 60}`}
            </div>
            <div className="ResendLabel" style={{color:timeLeft > 0?'#6c757d':'#d0004b',pointerEvents:timeLeft > 0?'none':''}} onClick={() => sendOtpHandle(true)}>{t('Verzend OTP opnieuw')}</div>
          </div>
          {
              otp_error &&
              <Box my="25px" color="#FE1717" textAlign="center">{otp_error}</Box>
          }
          <Button
            bg={
              otpInput.input1 &&
              otpInput.input2 &&
              otpInput.input3 &&
              otpInput.input4
                ? "#D0004B"
                : "#A0AEC0"
            }
            text="Nu inloggen"
            disabled={
              !(
                otpInput.input1 &&
                otpInput.input2 &&
                otpInput.input3 &&
                otpInput.input4
              )
            }
            margin="auto"
            mt="18px"
            mb="18px"
            textColor="#fff"
            onClick={submitOtpHandle}
          />
        </>
      ) : null}
      <div>
          <p>{t('Heeft u geen account?')}{" "}
            <b style={{ color: "#D0004B", cursor: "pointer" }} onClick={() => handleClickRegister()}>
               {t('Registreer u bij ons')}</b>
        </p>
      </div>
    </div>
  );
};

export default MosqueLogin;
