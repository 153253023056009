import React, { useEffect, useState } from "react";
import Table from "../../../../../components/Table/Table";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Searchbar from "../../../../../components/Searchbar";
import links from "../../../../../constants/customerFilters.json";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import axios from "axios";
import ProfileModal from "./ProfileModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCities,
  fetchCustomerInfoList,
  sendMemberReminders,
  setMemberDetailsVisible,
} from "../../../mosqueAction";
import UploadXls from "./UploadXls";
import Button from "../../../../../components/Buttons";
import Checkbox from "../../../../../components/Checkbox";
import { isMonthUpcoming, showNotification } from "../../../../../util/Utility";
import dayjs from "dayjs";
import PaginationV2 from "../../../../../components/Pagination/PaginationV2";
import CheckboxMenu from "./CheckBoxMenu";
import styled from "styled-components";
import truncate from "lodash/truncate";
import { background, color, height } from "styled-system";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import Icon from "../../../../../components/Icon";

const BoxContainer = styled(Box)`
  background: #f5f5f5;
  width: 220px;
  padding: 10px;
  border-radius: 8px;
  margin-left: 10px;
  height: 38px;
`;

const TemplateTableData = [
  {
    heading: "S.No.",
  },
  {
    heading: "Naam",
  },
  {
    heading: "Jan",
  },
  {
    heading: "Feb",
  },
  {
    heading: "Mrt",
  },
  {
    heading: "Apr",
  },
  {
    heading: "Mei",
  },
  {
    heading: "Jun",
  },
  {
    heading: "Jul",
  },
  {
    heading: "Aug",
  },
  {
    heading: "Sep",
  },
  {
    heading: "Okt",
  },
  {
    heading: "Nov",
  },
  {
    heading: "Dec",
  },
  {
    heading: "Downloaden",
  },
];

function MemberTable({
  isFilterBlocked,
  blockedView,
  blockedViewTitle,
  infoPage = true,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [qtext, setValue] = useState("");
  const [infoModal, setInfoModal] = useState(false)
  const bizIndustryLinks = links;
  const [filters, setFilters] = useState({});
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const isMemberModalVisible = useSelector(
    (state) => state.mosqueReducer.isMemberModalVisible
  );

  const customerList = useSelector(
    (state) => state.mosqueReducer.customerInfo?.customerInfoList
  );
  const is_sending_reminder = useSelector(
    (state) => state.mosqueReducer.is_sending_reminder
  );
  const customerInfoCount = useSelector(
    (state) => state.mosqueReducer.customerInfo?.customerInfoCount
  );
  const customerPageNum = useSelector(
    (state) => state.mosqueReducer.customerInfo?.pageNum
  );
  const customerPageSize = useSelector(
    (state) => state.mosqueReducer.customerInfo?.pageSize
  );
  const customerInfoPayments = useSelector(
    (state) => state.mosqueReducer.customerInfo?.customerInfoPayments
  );
  const isFetching = useSelector(
    (state) => state.mosqueReducer?.customerInfo?.isFetching
  );
  const [selectedUsers, setSelectedUser] = useState({});
  

  useEffect(() => {
    if (activeEntity?._id) {
      const filteredDetails = {...filters, isApproved:true}
      dispatch(
        fetchCustomerInfoList({
          entity_id: activeEntity?._id,
          pageSize: customerPageSize,
          pageNum: customerPageNum,
          filters:filteredDetails,
        })
      );
    }
  }, [dispatch, activeEntity, customerPageSize, customerPageNum, filters]);

  function refetchCustomerList() {
    const filteredDetails = {...filters, isApproved:true}
    dispatch(
      fetchCustomerInfoList({
        entity_id: activeEntity?._id,
        pageSize: customerPageSize,
        pageNum: customerPageNum,
        filters: filteredDetails,
      })
    );
  }
  const getStatus = () => {
    if (!!filters?.length) {
      return filters.find((item) => item?.status === "INACTIVE");
    }
  };

  

  useEffect(() => {
    getStatus();
    const newData = { heading: "All" };
    if (!!getStatus() && getStatus()?.status === "INACTIVE") {
      TemplateTableData[0] = newData;
    } else {
      TemplateTableData[0] = { heading: "S.No." };
    }
  }, [filters]);

  const onPageChange = (pageNum) => {
    const filteredDetails = {...filters, isApproved:true}
    dispatch(
      fetchCustomerInfoList({
        entity_id: activeEntity?._id,
        pageSize: customerPageSize,
        pageNum: pageNum,
        filters:filteredDetails,
      })
    );
  };

  const handleFilterChange = ({ pageNum, filters }) => {
    const filteredDetails = {...filters, isApproved:true}
    dispatch(
      fetchCustomerInfoList({
        entity_id: activeEntity?._id,
        pageSize: customerPageSize,
        pageNum: pageNum,
        filters:filteredDetails,
      })
    );
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const renderPaymentStatus = (item, month) => {
    const filteredData = item?.payments?.filter(
      (payment) => payment.month === month
    );
    //console.log("Filtered data for month ", month, ":", filteredData);
    if (filteredData?.length > 0) {
      return filteredData.map((payment) => {
        let color = "";
        let tooltipColor = "";
        switch (payment.status) {
          case "FAILED":
          case "MISSED":
            color = "red-color";
            tooltipColor = "#FE1717";
            break;
          case "PENDING":
          case "PROCESSING":
            color = "orange-color";
            tooltipColor = "#eb780d";
            break;
          case "SUCCESS":
            color = "green-color";
            tooltipColor = "#00AC4F";
            break;
        }
        return (
          <div key={month} style={{ display: "flex" }}>
            {filteredData.map((payment) => (
              <Tooltip
                key={payment._id}
                title={
                  <div>
                    <strong>{t("Payment Status")}</strong>
                    <div>
                      <span style={{ color: tooltipColor }}>●</span>{" "}
                      {capitalizeFirstLetter(payment.status)}
                    </div>
                  </div>
                }
              >
                <Box className={color} />
              </Tooltip>
            ))}
          </div>
        );
      });
    } else {
      return <Box ml="7px">{"-"}</Box>;
    }
  };

  const handleChangeFilter = (val) => {
    const { pageNum, filters } = val;
    let updatedFilters = { ...filters };
    updatedFilters[val.key_name] = val.value;
    updatedFilters.value = val.value;
    setFilters(updatedFilters);
    handleFilterChange({
      pageNum: 1,
      filters: updatedFilters,
    });
  };

  const handleSetUser = (id) => {
    let newUser = { ...selectedUsers };
    newUser[id] = !newUser[id] ? 1 : 0;
    setSelectedUser(newUser);
  };

  const showMemberDetails = (item, val = true) => {
    dispatch(setMemberDetailsVisible(item, val));
    if (item?.country) {
      dispatch(fetchCities(item?.country));
    }
  };

  const renderMemberList = () => {
    if (!customerList) {
      return null; // Or render a loading indicator
    }
    const startingSerialNumber = (customerPageNum - 1) * customerPageSize + 1;
    console.log("customer list ---->", customerList);
    return customerList
      .filter((item) => item.isApproved === true)
      .map((item, index) => {
        const currentIndex = startingSerialNumber + index; // Calculate current serial number
        return [
          currentIndex,
          <div
            onClick={() => showMemberDetails(item)}
            style={{
              display: "block",
              color: item?.isDeactivated ? "gray" : "",
            }}
          >
            <h6
              style={{
                cursor: "pointer",
                fontWeight: "600",
                paddingTop: !item?.paymentMode ? "8px" : "",
              }}
              title={item?.name || "NAA"}
              onMouseOver={(e) => (e.target.title = item?.name)}
              onMouseOut={(e) =>
                (e.target.title = item?.name
                  ? truncate(item?.name, { length: 15 })
                  : "NA")
              }
            >
              {item?.name ? truncate(item?.name, { length: 15 }) : "NA"}{" "}
              {item?.isDeactivated ? "(inActief)" : ""}
            </h6>
            <div style={{display:'flex'}}>
              <small
                style={{
                  color: "#d9534f",
                  background: "#f5f5f5",
                  borderRadius: "4px",
                  paddingLeft:item?.isMobileOnBoarded ? '0px':'2px',
                  paddingRight:item?.isMobileOnBoarded ? '0px':'2px',
                  marginRight: item?.isMobileOnBoarded ? "0px" : "8px",
                }}
              >
                {item?.isMobileOnBoarded ? null : t("Offline")}
              </small>
              {item?.paymentMode && (
                <div>
                  <small
                    style={{
                      color: "#4763E4",
                      background:
                        item?.paymentMode === "manual"
                          ? "#E3F2FD"
                          : item?.paymentMode === "automatic"
                          ? "#CEFFFF"
                          : "",
                      borderRadius: "4px",
                      padding: "2px 4px",
                    }}
                  >
                    {item?.paymentMode === "manual" ? t("handmatig") : null}
                    {item?.paymentMode === "automatic"
                      ? t("Automatisch")
                      : null}
                  </small>
                  <small
                    style={{
                      color: "#00AC4F",
                      background: item?.paymentMethod ? "#E5F7ED" : "",
                      borderRadius: "4px",
                      marginLeft: "8px",
                      padding: "2px 4px",
                    }}
                  >
                    {item?.paymentMethod}
                  </small>
                </div>
              )}
            </div>
          </div>,
          renderPaymentStatus(item, 1),
          renderPaymentStatus(item, 2),
          renderPaymentStatus(item, 3),
          renderPaymentStatus(item, 4),
          renderPaymentStatus(item, 5),
          renderPaymentStatus(item, 6),
          renderPaymentStatus(item, 7),
          renderPaymentStatus(item, 8),
          renderPaymentStatus(item, 9),
          renderPaymentStatus(item, 10),
          renderPaymentStatus(item, 11),
          renderPaymentStatus(item, 12),
          <div
            style={{
              cursor: "pointer",
              border:item.pdf_registeration_link ?"1px solid #D0004B":"1px solid #a0aec0",
              borderRadius: "16px",
              fontSize: "10px",
              width: "100%",
              fontWeight: "700",
              pointerEvents: item.pdf_registeration_link ? "" : "none",
            }}
          >
            <a
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                color: item.pdf_registeration_link ?"#D0004B":"#a0aec0",
              }}
              href={`https://${item?.pdf_registeration_link}` || ""}
              download="download.pdf"
              target="_blank"
              rel="noreferrer"
              id="pdfDownloadLink"
            >
              <Icon iconName={item.pdf_registeration_link?"download":"downloadGrey"} />
              PDF
            </a>
          </div>,
        ];
      });
  };
  console.log("Customer List", customerList);
  const getConvertedMemberIds = () => {
    let member_subscriptions = [];
    for (const key in selectedUsers) {
      member_subscriptions.push(key);
    }
    return member_subscriptions;
  };

  const sendReminder = async () => {
    let members = getConvertedMemberIds();
    if (members.length > 0) {
      dispatch(
        sendMemberReminders({
          entity_id: activeEntity?._id,
          members,
        })
      );
    } else {
      showNotification("error","Please Select Users");
    }
  };

  const hasAttributeWithValueOne = Object.values(selectedUsers).some(
    (value) => value === 1
  );

  const handleRedirection = () => {
    window.location.href = `/mosque/${activeEntity?._id}/info`;
  };

  const handleOnClick = () => {
    if (Object.keys(selectedUsers)?.length === customerInfoCount) {
      setSelectedUser({});
    } else {
      setSelectedUser({});
      let newUser = {};
      customerList.map((item) => {
        newUser[item?._id] = !newUser[item?._id] ? 1 : 0;
      });
      setSelectedUser(newUser);
    }
  };

  const isAllAttributesSetToOne = () => {
    if (Object.keys(selectedUsers)?.length === customerInfoCount) {
      return !!Object.keys(selectedUsers).length
        ? Object.values(selectedUsers).every((value) => value === 1)
        : false;
    }
    return false;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (activeEntity?._id) {
        const filteredDetails = {...filters, isApproved:true };
        filteredDetails.qtext = qtext;
        dispatch(
          fetchCustomerInfoList({
            entity_id: activeEntity?._id,
            pageSize: customerPageSize,
            pageNum: 1,
            filters: filteredDetails,
          })
        );
      }
    }
  };

  const handleButton = (val) => {
    if (val === "") {
      if (activeEntity?._id) {
        const filteredDetails = {...filters, isApproved:true};
        dispatch(
          fetchCustomerInfoList({
            entity_id: activeEntity?._id,
            pageSize: customerPageSize,
            pageNum: customerPageNum,
            filters: filteredDetails,
          })
        );
      }
    }
    setValue(val);
  };

  const onCheckboxChange = (selection) => {
    let updatedFilters = {};
    setFilters(selection);
    const payment_statuses_data = selection.filter((item) => item["status"]);
    console.log("Selected payments status:", payment_statuses_data);
    const recurring_types_data = selection.filter(
      (item) => item["paymentMode"]
    );
    console.log("Selected mode :", recurring_types_data);
    if (!!payment_statuses_data?.length) {
      updatedFilters["status"] = payment_statuses_data.map(
        (item) => item.status
      );
    }
    if (!!recurring_types_data?.length) {
      updatedFilters["paymentMode"] = recurring_types_data.map(
        (item) => item.paymentMode
      );
    }
    console.log("handle filter :", updatedFilters);
    handleChangeFilter({ pageNum: 1, filters: selection });
  };

  const tableclassName = infoPage
    ? `table-bg member-info-table pb-10`
    : "table-bg mosque-unpaid-table pb-4";

  return (
    <>
      <div
        className={
          customerList.length >= 9
            ? tableclassName + " member-info-table-height-max"
            : tableclassName + " member-info-table-height-min"
        }
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px="20px"
          py="28px"
        >
          <div style={{display:'flex', gap:'50px', alignItems:'center'}}>
          <Label fontSize="20px" fontWeight={700}>
            {blockedView ? blockedViewTitle : t("Ledeninformatie")}
          </Label>
          <UploadXls refetchCustomerList={refetchCustomerList} />
           <div className="d-flex align-items-center gap-2">
           <a href="https://mosqapp.nyc3.digitaloceanspaces.com/sample_mosqapp_member.xlsx" download>
          Sample xls
          </a>
          {/* <Tooltip title={
                        <ul>
                          <li>Use this ID {activeEntity?._id} for the Mosque Reference ID column.</li>
                          <li>The Subscription ID can be found under the Membership plan in the Memberships tab.</li>
                          <li><strong>For Free Subscriptions:</strong> Set the Subscription ID and Payment mode to "null" (all lowercase), and leave the Customer IBAN and Customer Account Holder Name fields empty.</li>
                          <li><strong>For Manual Subscriptions:</strong>  Set the Payment mode to "manual" (all lowercase), and leave the Customer IBAN and Customer Account Holder Name fields empty.</li>
                          <li><strong>For Autopay Subscriptions:</strong> Set the Payment mode to "automatic" (all lowercase), and ensure the Customer IBAN and Customer Account Holder Name fields are filled with valid data.</li>
                        </ul>
                      }>
           <Icon iconName="infoRed"/>
          </Tooltip> */}
          <span onClick={() => setInfoModal(true)} style={{ cursor: "pointer" }}>
            <Icon iconName="infoRed" />
           </span>
           </div>
          </div>
          <Box className="d-flex">
            <Searchbar
              value={qtext}
              setValue={(e) => handleButton(e.target.value)}
              placeholder={t("Zoeken")}
              fontSize="12px"
              border="0px solid rgba(0,0,0,.08)"
              searchIcon
              backgroundColor="#F5F5F5"
              color="#000"
              borderRadius="10px"
              width="252px"
              height="38px"
              paddingLeft="25px"
              position="relative"
              right="20px"
              inputProps={{
                type: "search",
                onKeyPress: handleKeyPress,
                height: "20px",
                marginBottom: "3px",
              }}
            />
            {!isFilterBlocked ? (
              <BoxContainer style={{ width: "240px" }}>
                <CheckboxMenu
                  options={bizIndustryLinks}
                  value={filters}
                  onChange={onCheckboxChange}
                  minheight="280px"
                  height="280px"
                />
              </BoxContainer>
            ) : null}
            {infoPage &&
            !!getStatus() &&
            getStatus()?.payment_status === "unpaid" ? (
              <Button
                type={hasAttributeWithValueOne ? "primary" : "outlineColor"}
                disabled={is_sending_reminder}
                onClick={() => sendReminder()}
                px="25px"
                minWidth="175px"
                minHeight="35px"
                ml="34px"
              >
                {is_sending_reminder ? t("Sending...") : t("Send Reminder")}
              </Button>
            ) : null}
          </Box>
        </Box>
        <Table
          gridTemplateColumnsHeader={
            "8% 20%  5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 11%"
          }
          gridTemplateColumnsBody={
            "8% 20%  5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 11%"
          }
          columns={TemplateTableData}
          data={renderMemberList()}
          isAll={isAllAttributesSetToOne()}
          onClickAll={() => handleOnClick()}
          fetching={isFetching}
          height="500px !important"
        />
        <DefaultModal
          show={isMemberModalVisible}
          onHide={() => showMemberDetails(null, false)}
          className="profile-modal"
          style={{ paddingRight: "0px", background: "blue" }}
          centered={false}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <ProfileModal
            onHide={showMemberDetails}
            refetchCustomerList={refetchCustomerList}
          />
        </DefaultModal>
        
            
      </div>
      <PaginationV2
        currentPage={customerPageNum}
        pageSize={customerPageSize}
        totalCount={customerInfoCount}
        onPageChange={onPageChange}
      />
      <Modal
        title={t("Instructions for Uploading")}
        open={infoModal}
        onCancel={() => setInfoModal(false)}
        footer={null}
      >
        <ul>
          <li>{t("Use this ID")} <strong>{activeEntity?._id}</strong> {t("for the Mosque Reference ID column.")}</li>
          <li>{t("The Subscription ID can be found under the Membership plan in the Memberships tab.")}</li>
          <li><strong>{t("For Free Subscriptions:")}</strong> {t("Set the Subscription ID and Payment mode to")} <code>"null"</code> (all lowercase), {t("and leave the Customer IBAN and Customer Account Holder Name fields empty.")}</li>
          <li><strong>{t("For Manual Subscriptions:")}</strong> {t("Set the Payment mode to")} <code>"manual"</code> (all lowercase), {t("and leave the Customer IBAN and Customer Account Holder Name fields empty.")}</li>
          <li><strong>{t("For Autopay Subscriptions:")}</strong> {t("Set the Payment mode to")} <code>"automatic"</code> (all lowercase), {t("and ensure the Customer IBAN and Customer Account Holder Name fields are filled with valid data.")}</li>
        </ul>
      </Modal>
    </>
  );
}

export default MemberTable;
